import React, { useEffect, useState } from "react";
import Title from "../../../extra/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import TableDrop, { SubTableScript } from "../../../extra/Tables/TableDrop";
import {
  staffLeaveDelete,
  staffLeaveGet,
} from "../../../../redux/slice/staffLeaveSlice";
import { Select } from "../../../extra/Inputs/Input";
import { warning } from "../../../extra/Swal/Alert";
import StaffLeaveAdd from "./StaffLeaveAdd";
import StaffLeaveDetails from "./StaffLeaveDetails";

const StaffLeave = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { admin } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [years, setYears] = useState("2024");

  const { staffLeave, staffLeaveYear } = useSelector(
    (state) => state.staffLeave
  );

  // Server Get
  useEffect(() => {
    if (admin?._id) {
      dispatch(
        staffLeaveGet({ staffId: admin._id, year: years, command: false })
      );
    }
  }, [admin, years]);

  useEffect(() => {
    if (admin?._id) {
      dispatch(
        staffLeaveGet({ staffId: admin._id, year: years, command: true })
      );
    }
  }, []);

  useEffect(() => {
    setData(staffLeave);
  }, [staffLeave]);

  const handleDeleteLeave = async (id) => {
    const data = warning("", "Remove Punch");
    let response;
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          response = await dispatch(staffLeaveDelete(id));
          console.log("response", response);
          if (response?.payload?.status) {
            dispatch(
              staffLeaveGet({
                staffId: response?.payload?.staffLeave?.staffId,
                year: response?.payload?.staffLeave?.year,
                command: false,
              })
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const staffLeaveTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>,
    },
    {
      Header: "Date",
      body: "thisMonthStart",
      bBody: "thisMonthEnd",
      bText: "to",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Month",
      body: "month",
      bBody: "_id",
      bText: "-",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Leave",
      body: "totalLeave",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Leave Days",
      body: "totalLeaveDays",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Holidays",
      body: "totalHolidays",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total All Days",
      body: "totalAllDays",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Hours",
      body: "totalHours",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      body: "",
      Cell: ({ row }) => (
        <ActionButton
          className="bg-blue-light text-blue  border-solid-blue-1  m-5-right actionClick"
          icon={`ri-information-line`}
        />
      ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
  ];
  const staffLeaveTables = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>,
    },
    {
      Header: "Leave Date",
      bBody: "leaveFrom",
      bText: "to",
      bTextClass: "text-second",
      body: "leaveTo",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Status",
      body: "",
      Cell: ({ row }) =>
        row.isLeave && !row.isHalfLeave ? (
          <button
            className={`bg-danger-light text-danger border-solid-danger-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
          >
            Full Leave
          </button>
        ) : (
          !row.isLeave &&
          row.isHalfLeave && (
            <button
              className={`bg-darkGray-light text-darkGray border-darkGray-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
            >
              Half Leave
            </button>
          )
        ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Day (Holiday",
      body: "allDays",
      aBody: "holidays",
      aBodyClass: "text-second",
      aText: "(",
      aTextClass: "text-second",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Days",
      body: "totalDays",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Leave Time",
      bBody: "timeFrom",
      bText: "to",
      bTextClass: "text-second",
      body: "timeTo",
      aBody: "breakTime",
      aBodyClass: "text-second",
      aText: "/",
      aTextClass: "text-second",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Hours",
      body: "totalHours",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Status",
      body: "status",
      Cell: ({ row }) =>
        row.status == 1 ? (
          <button
            className={`bg-blue-light text-blue border-solid-blue-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
          >
            pending
          </button>
        ) : row.status == 2 ? (
          <button
            className={`bg-success-light text-success border-solid-success-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
          >
            accepted
          </button>
        ) : (
          row.status == 3 && (
            <button
              className={`bg-second-light text-second border-second-blue-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
            >
              rejected
            </button>
          )
        ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      body: "-",
      Cell: ({ row }) =>
        row.status == 1 ? (
          <>
            <ActionButton
              className="bg-darkGray-light text-darkGray  border-solid-darkGray-1 m-5-right "
              icon={`ri-pencil-line`}
              onClick={() => {
                dispatch(openDialog({ type: "leave", data: { ...row } }));
              }}
            />
            <ActionButton
              className="bg-danger-light text-danger  border-solid-danger-1  m-5-right actionClick"
              icon={`ri-delete-bin-6-line`}
              onClick={() => {
                handleDeleteLeave(row._id);
              }}
            />
            <ActionButton
              className="bg-blue-light text-blue  border-solid-blue-1  m-5-right "
              icon={`ri-information-line`}
              onClick={() => {
                dispatch(
                  openDialog({ type: "staffLeaveDetails", data: { ...row } })
                );
              }}
            />
          </>
        ) : (
          "-"
        ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
  ];

  return (
    <>
      <SubTableScript datas={[data]} />
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Staff Leave"} icon={`ri-arrow-right-circle-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Select
              option={staffLeaveYear}
              defaultValue={years}
              onChange={(e) => setYears(e)}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(
                  openDialog({
                    type: "leave",
                    text: { staffCode: admin?.staffCode },
                  })
                );
              }}
            />
          </div>
        </div>
      </div>

      <TableDrop
        data={data}
        mapData={staffLeaveTable}
        subArryData={`leave`}
        subMapData={staffLeaveTables}
      />

      {dialogue && dialogueType === "leave" && <StaffLeaveAdd />}
      {dialogue && dialogueType === "staffLeaveDetails" && (
        <StaffLeaveDetails />
      )}
    </>
  );
};

export default StaffLeave;
