import React, { useEffect, useRef, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { remindingFeeGet } from "../../../../redux/slice/installmentSlice";
import MaleImg from "../../../../assets/images/male.png";
import FemaleImg from "../../../../assets/images/female.png";
import moment from "moment";
import { baseURL } from "../../../util/config";
import { closeDialog, openDialog } from "../../../../redux/slice/dialogSlice";
import InstallmentDetails from "./InstallmentDetails";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
const Installment = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { remindingFee, remindingFeeCount } = useSelector(
    (state) => state.installment
  );

  // Pagination BOTH

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
  };
  // Server Get
  useEffect(() => {
    dispatch(remindingFeeGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(remindingFeeGet({ ...payload, command: true }));
  }, []);
  useEffect(() => {
    setData(remindingFee);
  }, [remindingFee]);

  const remindingFeeTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="d-flex align-items-center">
          <span>
            <div className="height-30 border-round-50 width-30 bg-second m-20-right overflow-hidden">
              <img
                src={
                  row?.studentPhoto
                    ? baseURL + row?.studentPhoto
                    : row?.gender == "male"
                    ? MaleImg
                    : FemaleImg
                }
                alt=""
                height={`100%`}
              />
            </div>
          </span>
          <span className="m-15-right">{row?.studentName}</span>
        </span>
      ),
      sorting: { type: "client" },
      class: "text-center",
    },
    {
      Header: "Inst.Inx",
      body: "totalInstallments",
      sorting: { type: "client" },
    },
    {
      Header: "Payment",
      Cell: ({ row }) => <span>{row.installmentCount}/-</span>,
    },
    { Header: "Course ", body: "course", sorting: { type: "client" } },
    {
      Header: "Total Fees",
      Cell: ({ row }) => <span>{row.totalFees}/-</span>,
    },
    {
      Header: "Whatsapp No",
      Cell: ({ row }) => <span>+91 {row.whatsappNo}</span>,
    },
    {
      Header: "Date",
      body: "createdAt",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span>
          <span className="text-second">
            {moment(row?.installmentDate[0]).format("DD-MM-YYYY")}{" "}
          </span>
          To
          <span className="text-second">
            {" "}
            {moment(row?.installmentDate[row.totalInstallments - 1]).format(
              "DD-MM-YYYY"
            )}
          </span>
        </span>
      ),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-blue-light text-blue border-solid-blue-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "studentInstallment",
                  data: { ...row },
                })
              )
            }
            icon={`ri-install-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Reminding Fees"} icon={`ri-wallet-3-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={remindingFeeTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <ActionButton
              className={`bg-success-light text-success border-solid-success-2 m-20-right`}
              bIcon={`ri-file-pdf-line`}
              text={`PDF`}
              onClick={() => {
                dispatch(openDialog({ type: "remindingFeeList" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={remindingFeeTable}
        Page={page}
        serverPerPage={rowsPerPage}
      />

      <Pagination
        type={"server"}
        onPageChange={handleChangePage}
        serverPerPage={rowsPerPage}
        totalData={remindingFeeCount}
        serverPage={page}
        setServerPage={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {dialogue && dialogueType === "studentInstallment" && (
        <InstallmentDetails />
      )}
      {dialogue && dialogueType === "remindingFeeList" && (
        <RemindingFeeList datas={remindingFee} />
      )}
    </>
  );
};

export default Installment;

export const RemindingFeeList = ({ datas }) => {
  console.log("datas", datas);

  const dispatch = useDispatch();

  const feeContentRef = useRef(null);

  const printDiv = () => {
    const styleElements = document.head.querySelectorAll("style");
    const linkElements = document.querySelectorAll("link");
    const content = feeContentRef.current.innerHTML;
    const newWindow = window.open("", "_blank");

    let newStyles = "";
    let newLinks = "";
    for (const style of styleElements) {
      newStyles += style.textContent;
    }
    for (const link of linkElements) {
      newLinks += link.outerHTML;
    }

    newWindow.document.write(`
    <html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/remixicon@3.4.0/fonts/remixicon.css" rel="stylesheet">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet"
  integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM" crossorigin="anonymous">
  ${newLinks}    
  <style>
        ${newStyles}
      </style\>
    </head\>
    <script src="https://kit.fontawesome.com/e9f488f24b.js" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-geWF76RCwLtnZ8qwWowPQNguL3RmwHVBC9FhGdlKrxdiJJigb/j/68SIy3Te4Bkz" crossorigin="anonymous"></script>
    <body>${content}</body>
    </html>
`);
    newWindow.document.close();
    newWindow.print();
    newWindow.onafterprint = () => newWindow.close();
  };

  return (
    <DialogBox
      columns={`col-sm-10 col-11`}
      foot={true}
      head={true}
      mainDialogClass={`bg-transparent `}
    >
      <div className="formBody">
        <div class="fee-container" ref={feeContentRef}>
          <div className="startFeesTable w-100">
            <table className="table bg-light m-0 border-solid-dark-2 w-100" border={2}>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Pending Fee</th>
                <th>Course</th>
                <th>Mobile Number</th>
                <th className="text-center">Date</th>
                <th width="300px">Description</th>
              </tr>
              {datas.map((res, i) => {
                return (
                  <tr>
                    <td className="text-center">{i + 1}</td>
                    <td>{res.studentName}</td>
                    <td>
                      {res.installmentCount}/- ({res.totalInstallments})
                    </td>
                    <td>{res.course}</td>
                    <td>+91 {res.whatsappNo}</td>
                    <td className="text-center">
                      <span className="text-second">
                        {moment(res?.installmentDate[0]).format("D-M-YY")}{" "}
                      </span>
                      To
                      <span className="text-second">
                        {" "}
                        {moment(
                          res?.installmentDate[res.totalInstallments - 1]
                        ).format("D-M-YY")}
                      </span>
                    </td>
                    <td></td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>

        <div className="actionButtonForPrint m20-top midBox">
          <Button
            className={`bg-second text-light border-solid-second-2`}
            bIcon={`ri-printer-line`}
            text={`Print`}
            type={`button`}
            onClick={() => printDiv()}
          />
          <Button
            className={`bg-darkGray text-light border-solid-darkGray-2 m-5-left`}
            bIcon={`ri-close-line`}
            text={`Cancel`}
            type={`button`}
            onClick={() => {
              dispatch(closeDialog());
            }}
          />
        </div>
      </div>
    </DialogBox>
  );
};
