import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  Image,
  Textarea,
  editData,
  objectToFormData,
  submitData,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { staffAdd, staffUpdate } from "../../../../redux/slice/staffSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import { generateNum } from "../../../util/fuction";

const StaffAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "staffForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addStaff = submitData(e);
    if (addStaff) {
      console.log("addStaff", addStaff);
      const formData = objectToFormData(addStaff);

      try {
        let response;
        if (dialogueData) {
          const payload = { formData, id: dialogueData._id };
          response = await dispatch(staffUpdate(payload)).unwrap();
        } else {
          response = await dispatch(staffAdd(formData)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`staffForm`}
      title={`Staff Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-sm-10 col-11`}
    >
      <div className="formBody">
        <div className="startForm">
          <h5 className="text-second">Staff Details :</h5>
          <div className="row align-items-center m-10-bottom">
            <div
              className={`${
                dialogueData ? "col-lg-4" : "col-lg-3"
              }  col-md-6 col-12`}
            >
              <Input
                type={`text`}
                id={`staffName`}
                name={`staffName`}
                label={`Staff Name`}
                errorMessage={`Enter staff Name`}
              />
            </div>
            <div
              className={`${
                dialogueData ? "col-lg-4" : "col-lg-3"
              }  col-md-6 col-12`}
            >
              <Input
                type={`number`}
                id={`staffCode`}
                name={`staffCode`}
                label={`Staff Code`}
                errorMessage={`Enter Staff Code`}
                validation={`^[a-zA-Z0-9]{6,6}$`}
                validationError={`Invalid Staff Code`}
                activeIcon={!dialogueData && `ri-information-line`}
                activClick={() => generateNum(6, "staffCode")}
                readOnly
              />
            </div>

            <div
              className={`${
                dialogueData ? "col-lg-4" : "col-lg-3"
              }  col-md-6 col-12`}
            >
              <Input
                type={`text`}
                id={`email`}
                name={`email`}
                label={`Email`}
                errorMessage={`Enter Email`}
              />
            </div>
            <div
              className={` ${
                dialogueData && "d-none"
              } col-lg-3  col-md-6 col-12`}
            >
              <Input
                type={`text`}
                id={`password`}
                name={`${!dialogueData && "password"}`}
                label={`Password`}
                errorMessage={`Enter Password`}
                ignore={`${dialogueData ? true : false}`}
              />
            </div>

            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`text`}
                id={`position`}
                name={`position`}
                label={`Position`}
                errorMessage={`Enter Position`}
              />
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`text`}
                id={`department`}
                name={`department`}
                label={`Department`}
                errorMessage={`Enter Department`}
              />
            </div>

            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`date`}
                id={`dob`}
                name={`dob`}
                label={`Date of Birth`}
                errorMessage={`Enter Date of Birth`}
              />
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`number`}
                id={`phoneNumber`}
                name={`phoneNumber`}
                label={`Mobile Number`}
                errorMessage={`Enter Mobile Number`}
                validation={`^[0-9]{10,10}$`}
                validationError={`Invalid Mobile Number`}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-12 ">
              <div className="row">
                <div className="col-6 align-self-center">
                  <Input
                    type={`radio`}
                    id={`male`}
                    name={`gender`}
                    label={`Male`}
                    value={`male`}
                  />
                </div>
                <div className="col-6 align-self-center">
                  <Input
                    type={`radio`}
                    id={`female`}
                    name={`gender`}
                    label={`Female`}
                    value={`female`}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`time`}
                id={`startTime`}
                name={`startTime`}
                label={`Start Time`}
                errorMessage={`Enter Start Time`}
                validationError={`Invalid Start Time`}
              />
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`time`}
                id={`endTime`}
                name={`endTime`}
                label={`End Time`}
                errorMessage={`Enter End Time`}
                validationError={`Invalid End Time`}
              />
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`time`}
                id={`breakTime`}
                name={`breakTime`}
                label={`Break Time (1 Hour = 1:00AM)`}
                errorMessage={`Enter Break Time`}
                validationError={`Invalid Break Time`}
              />
            </div>
          </div>
          <h5 className="text-second">Personal Details :</h5>
          <div className="row align-items-center m-10-bottom">
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`number`}
                id={`experience`}
                name={`experience`}
                label={`Experience`}
                errorMessage={`Enter Experience`}
              />
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`text`}
                id={`lastCompany`}
                name={`lastCompany`}
                label={`Last Company`}
                errorMessage={`Enter Last Company`}
              />
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`number`}
                id={`salary`}
                name={`salary`}
                label={`Salary`}
                errorMessage={`Enter Salary`}
              />
            </div>
            <div className="col-lg-3  col-md-6 col-12">
              <Input
                type={`date`}
                id={`joiningDate`}
                name={`joiningDate`}
                label={`Joining Date`}
                errorMessage={`Enter Joining Date`}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <Textarea
                id={`address`}
                name={`address`}
                label={`Address`}
                row={5}
                errorMessage={`Enter Address`}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <Input
                type={`text`}
                id={`adharCard`}
                name={`adharCard`}
                label={`Aadhar Card`}
                errorMessage={`Enter Aadhar Card`}
              />
              <Input
                type={`text`}
                id={`panCard`}
                name={`panCard`}
                label={`PAN Card`}
                errorMessage={`Enter Pan Card`}
              />
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <Image
                type={`file`}
                id={`image`}
                name={`image`}
                label={`Image`}
                errorMessage={`Enter Image`}
              />
            </div>
          </div>
          <h5 className="text-second">Account Details :</h5>
          <div className="row align-items-center m-10-bottom">
            <div className="col-lg-4  col-md-6 col-12">
              <Input
                type={`number`}
                id={`accountNo`}
                name={`accountNo`}
                label={`Account No`}
                errorMessage={`Enter Account No`}
              />
            </div>
            <div className="col-lg-4  col-md-6 col-12">
              <Input
                type={`text`}
                id={`IFSC`}
                name={`IFSC`}
                label={`IFSC code`}
                errorMessage={`Enter IFSC code`}
              />
            </div>
            <div className="col-lg-4  col-md-6 col-12">
              <Input
                type={`text`}
                id={`holderName`}
                name={`holderName`}
                label={`Holder Name`}
                errorMessage={`Enter Holder Name`}
              />
            </div>
          </div>
          <h5 className="text-second">Emergency Contact :</h5>
          <div className="row align-items-center m-10-bottom">
            <div className="col-lg-4  col-md-6 col-12">
              <Input
                type={`text`}
                id={`emergencyName`}
                name={`emergencyName`}
                label={`Emergency Name`}
                errorMessage={`Enter Emergency Name`}
              />
            </div>
            <div className="col-lg-4  col-md-6 col-12">
              <Input
                type={`text`}
                id={`emergencyRelation`}
                name={`emergencyRelation`}
                label={`Emergency Relation`}
                errorMessage={`Enter Emergency Relation`}
              />
            </div>
            <div className="col-lg-4  col-md-6 col-12">
              <Input
                type={`Number`}
                id={`emergencyNumber`}
                name={`emergencyNumber`}
                label={`Emergency Number`}
                errorMessage={`Enter Emergency Number`}
              />
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default StaffAdd;
