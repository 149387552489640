import { useSelector } from "react-redux";
import Button from "../../../extra/Buttons/Button";
import { baseURL } from "../../../util/config";
import Logo from "../../../../assets/images/logo.png";
import Input from "../../../extra/Inputs/Input";
import DialogBox from "../../../extra/DialogueBox/DialogBox";

const StudentLeaveDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { admin } = useSelector((state) => state.auth);

  return (
    <DialogBox
      columns={`col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-11`}
      foot={true}
      head={true}
    >
      <div className="row formBody">
        <div className="col-xl-12">
          <div className="mainLogoPart">
            <div className="logoImg  width-sm-90 width-50 m-auto m-10-bottom">
              <img src={Logo} alt="logo" width={`100%`} />
            </div>
          </div>
          <div className="staffImage m-auto m-10-bottom hw-100 border-solid-second-3 border-radius-16 overflow-hidden">
            <img
              src={baseURL + (dialogueData?.image || admin?.image)}
              alt="staff image"
              width={"100%"}
              height={"100%"}
            />
          </div>
          <div className="FacultyName text-center text-second">
            <h5>{dialogueData?.studentName || admin?.studentName}</h5>
          </div>
          <div className="Leave Status text-center text-second m20-y">
            {dialogueData?.status == 1 ? (
              <Button
                className={`bg-blue-light text-blue border-solid-blue-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
                text={`Pending`}
              />
            ) : dialogueData?.status == 2 ? (
              <Button
                className={`bg-success-light text-success border-solid-success-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
                text={`Accepted`}
              />
            ) : (
              dialogueData?.status == 3 && (
                <Button
                  className={`bg-danger-light text-danger border-solid-danger-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
                  text={`Rejected`}
                />
              )
            )}
          </div>
          <div className="mainLeaveDetails w-100 overflow-auto">
            <div className="mainProfileBoard mainAdminProfile">
              <div className="mainProfileBox">
                <div className="row">
                  <div className=" col-12 m-auto">
                    <div className="row m40-sm-top m20-top">
                      <div className=" col-12">
                        <div className="adminDetails p-20">
                          <div className="adminProfileTitle">
                            Leave Information
                          </div>
                          <div className="adminDetailsBox">
                            <div className="adminDetailTitle">Leave Start</div>
                            <Input
                              type={`date`}
                              value={dialogueData?.leaveFrom}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="adminDetailsBox">
                            <div className="adminDetailTitle">Leave End</div>
                            <Input
                              type={`date`}
                              value={dialogueData?.leaveTo}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="adminDetailsBox ">
                            <div className="adminDetailTitle">Leave Day</div>
                            <Input
                              type={`text`}
                              value={`${dialogueData?.allDays} Days`}
                              defaultValue={`--`}
                              disabled={true}
                              className={`text-capitalize`}
                            />
                          </div>
                          <div className="adminDetailsBox ">
                            <div className="adminDetailTitle">Holidays</div>
                            <Input
                              type={`text`}
                              value={`- ${dialogueData?.holidays} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="adminDetailsBox border-top-gray2-1">
                            <div className="adminDetailTitle">Total Leave</div>
                            <Input
                              type={`text`}
                              value={`= ${dialogueData?.totalDays} Days`}
                              defaultValue={`--`}
                              disabled={true}
                            />
                          </div>
                          <div className="adminDetailsBox">
                            <div className="adminDetailTitle">Description</div>
                            <div className="inputData fs-14 fw-600 text-darkGray">
                              {dialogueData?.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default StudentLeaveDetails;
