import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { editData, submitData } from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { courseAdd, courseUpdate } from "../../../../redux/slice/courseSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";

const CourseAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "courseForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addCourse = submitData(e);

    if (addCourse) {
      try {
        let response;
        if (dialogueData) {
          const payload = { addCourse, id: dialogueData._id };
          response = await dispatch(courseUpdate(payload)).unwrap();
        } else {
          response = await dispatch(courseAdd(addCourse)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`courseForm`}
      title={`Course Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-3 col-md-5 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-12">
          <Input
            type={`text`}
            id={`courseName`}
            name={`courseName`}
            label={`course`}
            errorMessage={`Enter course`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default CourseAdd;
