import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../extra/Buttons/Button";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  attendanceAdd,
  studentAttendanceGet,
} from "../../../../redux/slice/studentAttendanceSlice";
import { setToast } from "../../../extra/toast";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import { submitData } from "../../../extra/Inputs/Input";

const StudentAttendanceAdd = ({ setChoiceYears, setChoiceMonth }) => {
  const { dialogueData, dialogueText } = useSelector((state) => state.dialogue);

  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("");

  const option = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];
  const currentYear = new Date().getFullYear();
  const numberOfYears = 10;

  const allYears = Array.from({ length: numberOfYears }, (_, index) =>
    (currentYear + index).toString()
  );

  useEffect(() => {
    if (dialogueText) {
      setSelectYear(dialogueText?.year);
    }
  }, [dialogueText]);

  const handleSubmit = async (e) => {
    const addAttendance = submitData(e);
    let addAttendanceNew = { year: selectYear, month: selectMonth };
    if (addAttendance || addAttendanceNew) {
      try {
        let response;

        response = await dispatch(attendanceAdd(addAttendanceNew)).unwrap();
        console.log(response, "response.data.status");
        if (response.status) {
          if (response?.studentAttendance?.length > 0) {
            await dispatch(
              studentAttendanceGet(response?.studentAttendance[0].year)
            );
            setChoiceMonth(response?.studentAttendance[0].monthId);
            setChoiceYears(response?.studentAttendance[0].year);
          }
          dispatch(closeDialog());
        } else {
          setToast(response.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`studentAttendanceAdd`}
      title={`Attendance Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody p-50-bottom">
        <div className="col-12">
          <div className="inputData m-10-bottom">
            <label>Months</label>
          </div>
          {option.map((res) => (
            <Button
              className={`${
                selectMonth == res.value
                  ? "bg-second text-light border-solid-second-2"
                  : "bg-third-light text-third border-solid-third-2"
              }   m-10-right m5-bottom`}
              text={res.name}
              type={`button`}
              onClick={() => setSelectMonth(res.value)}
            />
          ))}
        </div>
        <div className="col-12">
          <div className="inputData m-10-bottom">
            <label>Years</label>
          </div>
          {allYears.map((res) => (
            <Button
              className={`${
                selectYear == res
                  ? "bg-second text-light border-solid-second-2"
                  : "bg-third-light text-third border-solid-third-2"
              }   m-10-right m5-bottom`}
              text={res}
              type={`button`}
              onClick={() => {
                !dialogueText?.year && setSelectYear(res);
              }}
              disabled={dialogueText?.year && selectYear != res && true}
            />
          ))}
        </div>
      </div>
    </DialogBox>
  );
};

export default StudentAttendanceAdd;
