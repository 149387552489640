import React, { useEffect, useState } from "react";
import { Select } from "../Inputs/Input";
import "./pagination.scss";

const Pagination = (props) => {
  const [pages, setPages] = useState([]);
  const {
    type,
    serverPage,
    setServerPage,
    serverPerPage,
    onPageChange,
    onRowsPerPageChange,
    totalData,
  } = props;

  const onPageChangeClient = (serverPage) => {
    setServerPage(serverPage);
  };

  useEffect(() => {
    const totalPages = Math.ceil(totalData / serverPerPage + 1);
    const range = Math.min(3, totalPages);
    const start = Math.max(1, serverPage - Math.floor(range / 2));
    const end = Math.min(start + range, totalPages);

    const pageNumbers = Array.from(
      { length: end - start },
      (_, i) => start + i
    );

    setPages(pageNumbers);
  }, [serverPage, totalData, serverPerPage]);

  const totalCount = Math.min((serverPage + 1) * serverPerPage, totalData);

  // back button
  const handleBackButtonClick = (event) => {
    onPageChange(event, serverPage - 1);
  };

  //next page button
  const handleNextButtonClick = (event) => {
    onPageChange(event, serverPage + 1);
  };

  const option = [10, 25, 50, 100, 200];

  return (
    <div className="pagination">
      <>
        {totalData > 0 && (
          <div className="client-pagination row betBox w-100">
            <div className="col-6">
              <div className="tableRang midBox">
                <Select
                  id={`pagination`}
                  option={option}
                  defaultValue={serverPerPage}
                  label={`Show `}
                  onChange={onRowsPerPageChange}
                  className={`midBox paginationSelect`}
                  btnClass={`mt-0`}
                  angle={true}
                />
                <p className="count">
                  {`${parseInt(
                    serverPage * serverPerPage + 1
                  )} - ${totalCount} of ${totalData}`}
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="tableAccess ">
                <div className="d-flex justify-content-end m-15-left mainPaginatinBtn">
                  <button
                    className={`paginationBtn fixedBtn ${
                      serverPage === 0 && "pageBtnDisable"
                    }`}
                    disabled={serverPage == 0}
                    onClick={() => onPageChangeClient(0)}
                  >
                    <i className="ri-arrow-left-double-fill"></i>
                  </button>

                  <button
                    className={`paginationBtn fixedBtn ${
                      serverPage === 0 && "pageBtnDisable"
                    }`}
                    disabled={serverPage == 0}
                    onClick={() => handleBackButtonClick()}
                  >
                    <i className="ri-arrow-left-s-line"></i>
                  </button>

                  {type === "server" &&
                    pages.map((serverPages) => {
                      return (
                        <button
                          key={serverPages}
                          onClick={() => onPageChangeClient(serverPages - 1)}
                          className={`paginationBtn paginationNumber ${
                            serverPage + 1 === serverPages
                              ? "active"
                              : "active-btn"
                          }`}
                        >
                          {serverPages}
                        </button>
                      );
                    })}
                  <button
                    className={`paginationBtn fixedBtn ${
                      serverPage === Math.ceil(totalData / serverPerPage) - 1 &&
                      "pageBtnDisable"
                    }`}
                    disabled={
                      serverPage === Math.ceil(totalData / serverPerPage) - 1
                    }
                    onClick={() => handleNextButtonClick()}
                  >
                    <i className="ri-arrow-right-s-line"></i>
                  </button>

                  <button
                    className={`paginationBtn fixedBtn ${
                      serverPage === Math.ceil(totalData / serverPerPage) - 1 &&
                      "pageBtnDisable"
                    }`}
                    disabled={
                      serverPage === Math.ceil(totalData / serverPerPage) - 1
                    }
                    onClick={() =>
                      onPageChangeClient(
                        Math.ceil(totalData / serverPerPage) - 1
                      )
                    }
                  >
                    <i className="ri-arrow-right-double-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Pagination;
