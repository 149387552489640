import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
import BatchAdd from "./BannerAdd";
import BatchDetails from "./BatchDetail";
import {
  bannerAction,
  bannerDelete,
  bannerGet,
} from "../../../../redux/slice/bannerSlice";
import ToggleSwitch from "../../../extra/Buttons/ToggleSwitch";
import { baseURL } from "../../../util/config";
import BannerAdd from "./BannerAdd";
const Banner = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { banner, bannerTotal } = useSelector((state) => state.banner);

  // Pagination BOTH

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    dispatch(bannerGet());
  }, []);

  useEffect(() => {
    setData(banner);
  }, [banner]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(bannerDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleBannerAction = (bannerId) => {
    const data = warning(
      `Banner`,
      `Are You Sure ${bannerId.isActive ? "Deactive" : "Active"}?`,
      `${bannerId.isActive ? "Deactive" : "Active"}`,
      `${
        bannerId.isActive ? "ri-close-circle-line" : "ri-checkbox-circle-line"
      }`,
      `${bannerId.isActive ? "second" : "success"}`
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(bannerAction(bannerId._id));
        }
      })
      .catch((err) => console.log(err));
  };

  const bannerTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <span>
          <img src={baseURL + row.image} alt="" />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "width-150",
    },
    { Header: "Mini Title", body: "miniTitle", sorting: { type: "client" } },
    { Header: "Big Title", body: "bigTitle", sorting: { type: "client" } },
    {
      Header: "Description",
      body: "description",
      sorting: { type: "client" },
      mainClass: "text-overflow-3",
    },
    {
      Header: "Active",
      body: "isActive",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleBannerAction(row)}
            value={row.isActive}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "banner",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-solid-danger-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Banner"} icon={`ri-group-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={bannerTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "banner" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={bannerTable}
        Page={page}
        serverPerPage={rowsPerPage}
      />

      <Pagination
        type={"server"}
        onPageChange={handleChangePage}
        serverPerPage={rowsPerPage}
        totalData={bannerTotal}
        serverPage={page}
        setServerPage={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {dialogue && dialogueType === "banner" && <BannerAdd />}
      {/* {dialogue && dialogueType === "bannerDetails" && <BatchDetails />} */}
    </>
  );
};

export default Banner;
