import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import {
  studentDelete,
  studentGet,
  studentSwitch,
} from "../../../../redux/slice/studentSlice";
import MaleImg from "../../../../assets/images/male.png";
import FemaleImg from "../../../../assets/images/female.png";
import { warning } from "../../../extra/Swal/Alert";
import { baseURL } from "../../../util/config";
import StudentAdd from "./StudentAdd";
import StudentDetails from "./StudentDetails";
import { useNavigate } from "react-router";
import StudentAttendanceDetails from "../StudentAttendance/StudentAttendanceDetails";
import InstallmentDetails from "../StudentInstallment/InstallmentDetails";
import StudentLeaveAdd from "../StudentLeave/StudentLeaveAdd";
import ToggleSwitch from "../../../extra/Buttons/ToggleSwitch";
const Student = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { student, studentTotal } = useSelector((state) => state.student);

  // Pagination BOTH

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
  };
  // Server Get
  useEffect(() => {
    dispatch(studentGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(studentGet({ ...payload, command: true }));
  }, []);
  useEffect(() => {
    setData(student);
  }, [student]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(studentDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleStudentAction = (studentData) => {
    const data = warning(
      `${studentData.isActive ? "Deactive" : "Active"}`,
      `Are You Sure ${studentData.isActive ? "Deactive" : "Active"}?`,
      `${studentData.isActive ? "Deactive" : "Active"}`,
      `${
        studentData.isActive
          ? "ri-checkbox-circle-line"
          : "ri-close-circle-line"
      }`,
      `${studentData.isActive ? "success" : "second"}`
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(studentSwitch(studentData._id));
        }
      })
      .catch((err) => console.log(err));
  };

  const studentTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="d-flex align-items-center">
          <span>
            <div className="height-30 border-round-50 width-30 bg-second m-20-right overflow-hidden">
              <img
                src={
                  row?.studentPhoto
                    ? baseURL + row?.studentPhoto
                    : row?.gender == "male"
                    ? MaleImg
                    : FemaleImg
                }
                alt=""
                height={`100%`}
              />
            </div>
          </span>
          <span className="m-15-right">{row?.studentName}</span>
        </span>
      ),
      sorting: { type: "client" },
      class: "text-center",
    },
    {
      Header: "Form No",
      body: "formNo",
      Cell: ({ row }) => <span>#{row?.formNo}</span>,
      sorting: { type: "client" },
    },
    {
      Header: "Batch",
      body: "batchTime",
    },
    {
      Header: "Course",
      body: "course",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Email",
      body: "email",
      sorting: { type: "client" },
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Gender",
      body: "gender",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Mobile No",
      body: "phoneNo",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Joining Date",
      body: "createdAt",
      Cell: ({ row }) => <span>{row?.admissionDate}</span>,
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Details",
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-success-light text-success border-solid-success-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "studentAttendance",
                  data: { ...row },
                })
              )
            }
            icon={`ri-user-follow-line`}
          />
          <ActionButton
            className="bg-blue-light text-blue border-solid-blue-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "studentInstallment",
                  data: { ...row },
                })
              )
            }
            icon={`ri-bank-card-2-line`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-solid-danger-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "pendingLeaves",
                  data: { ...row },
                })
              )
            }
            icon={`ri-file-add-line`}
          />
        </span>
      ),
    },
    {
      Header: "Action",
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "student",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-second-light text-second border-solid-second-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
          <ActionButton
            className="bg-warning-light text-warning border-solid-warning-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "studentDetails",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-information-line`}
          />
        </span>
      ),
    },
    {
      Header: "Active",
      body: "isActive",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleStudentAction(row)}
            value={row?.isActive}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Student"} icon={`ri-user-3-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={studentTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "student" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={studentTable}
        Page={page}
        serverPerPage={rowsPerPage}
      />

      <Pagination
        type={"server"}
        onPageChange={handleChangePage}
        serverPerPage={rowsPerPage}
        totalData={studentTotal}
        serverPage={page}
        setServerPage={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {dialogue && dialogueType === "student" && <StudentAdd />}
      {dialogue && dialogueType === "studentDetails" && <StudentDetails />}
      {dialogue && dialogueType === "studentInstallment" && (
        <InstallmentDetails />
      )}
      {dialogue && dialogueType === "studentAttendance" && (
        <StudentAttendanceDetails />
      )}
      {dialogue && dialogueType === "pendingLeaves" && <StudentLeaveAdd />}
    </>
  );
};

export default Student;
