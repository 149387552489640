import React from "react";
import "./buttons.scss";

const ToggleSwitch = (props) => {
  return (
    <>
      <label class="switch">
        <input
          type="checkbox"
          checked={props.value}
          onClick={props.onClick}
          // className="customCheckbox"
          className="toggle-checkbox"
        />
        <span class="slider"></span>
      </label>
    </>
  );
};

export default ToggleSwitch;
