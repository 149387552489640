import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input, { submitData } from "../extra/Inputs/Input";
import { login } from "../../redux/slice/authSlice";
import Button from "../extra/Buttons/Button";
import LoginImage from "../../assets/images/loginBG.png";
import ParticleBackground from "./ParticleBackground";

const Login = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  useEffect(() => {
    isAuth && navigate("/admin");
  }, [isAuth]);

  const handleSubmit = async (e) => {
    const loginData = submitData(e);
    console.log("loginData", loginData);
    if (loginData) {
      try {
        let response = await dispatch(login(loginData)).unwrap();
        response?.status && navigate("/admin");
      } catch (err) {}
    }
  };

  return (
    <>
      <ParticleBackground particleColor={`#5757B7`} particleValue={150} />
      <div
        className="mainLoginPage"
        style={{ backgroundImage: `url(${LoginImage})` }}
      >
        <div className="row h-100 align-items-center justify-content-center particles-js-canvas-el">
          <div className="col-12 h-100 midBox border-md-solid-left-gray-1 bgBlur">
            <div className="loginDiv w-100 p-10">
              <div
                className="loginPage m-auto text-center"
                style={{ width: "400px" }}
              >
                <div className="loginTitle m-24-bottom">
                  <h1 className="fw-bold text-light">Log In</h1>
                </div>
                <form onSubmit={handleSubmit} id="loginForm">
                  <div className="loginInput">
                    <Input
                      type={`text`}
                      id={`email`}
                      label={`Email`}
                      placeholder={`Email`}
                      name={`email`}
                      errorMessage={`Enter Email`}
                      defaultValue={`admin@admin.com`}
                    />
                    <Input
                      type={`password`}
                      id={`password`}
                      label={`Password`}
                      placeholder={`Password`}
                      name={`password`}
                      errorMessage={`Enter Password`}
                      defaultValue={123456}
                    />
                  </div>
                  <div className="loginButton">
                    <Button
                      type={`submit`}
                      className={`bg-second-light text-second border-solid-second-1 w-100 fw-600 cursor-pointer m-10-top`}
                      text={`Submit`}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
