import React, { useEffect, useState } from "react";
import Title from "../../extra/Title/Title";
import Input, { editData, submitData } from "../../extra/Inputs/Input";
import Button, { ActionButton } from "../../extra/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAdminData,
  updateAdminPassword,
  updateImage,
} from "../../../redux/slice/authSlice";
import { baseURL } from "../../util/config";
import MaleProfile from "../../../assets/images/maleProfile.png";
import FemaleProfile from "../../../assets/images/femaleProfile.png";
import ParticleBackground from "../../Page/ParticleBackground";

const Profile = () => {
  const { admin } = useSelector((state) => state.auth);
  const { loginUser } = useSelector((state) => state.dashBoard);
  const sessionUserId =
    loginUser || admin || sessionStorage.getItem(JSON.parse("token"));

  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");

  const [editUserData, setEditUserData] = useState(false);
  const [editUserPassData, setEditUserPassData] = useState(false);

  useEffect(() => {
    if (sessionUserId) {
      editData(sessionUserId, "adminDetailsForm");
    }
  }, [sessionUserId]);

  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };
  const dispatch = useDispatch();
  // Server Get
  const handleImage = () => {
    const formData = new FormData();
    formData.append("image", image);
    dispatch(updateImage(formData));
  };

  const handleSubmit = async (e) => {
    const editAdmin = submitData(e);
    if (editAdmin) {
      await dispatch(updateAdminData(editAdmin)).unwrap();
      setEditUserData(false);
    }
  };
  const handleSubmitPassword = async (e) => {
    const editAdminPass = submitData(e);
    if (editAdminPass) {
      let res = await dispatch(updateAdminPassword(editAdminPass)).unwrap();
      res?.status && setEditUserPassData(false);
    }
  };

  return (
    <div>
      <div className="col-12">
        <Title name={"Profile"} icon={`ri-profile-line`} />
      </div>
      <div className="mainProfileBoard mainAdminProfile">
        <div className="mainProfileBox">
          <div className="row m-sm-40-top m-20-top">
            <div className="col-xxl-9 col-xl-10 col-lg-11 col-12 m-auto">
              <div className="adminBgDetails p-sm-30-y p-20 p-xxl-130-x p-xl-90-x p-50-x">
                <ParticleBackground
                  particleColor={`#a02829`}
                  particleValue={100}
                />
                <div className="mainProfileDetails betBox flex-sm-row flex-column">
                  <div className="profilePosition text-second order-sm-0 order-1 m-sm-0-bottom m-35-bottom">
                    <h2 className="fs-md-40 fs-30 m-5-bottom">CEO</h2>
                    <p className="fw-600 fs-md-14 fs-12">Admin Department</p>
                  </div>
                  <div className="profileImageVector hw-md-180 hw-100 m-sm-0 m-20-bottom">
                    <img src={MaleProfile} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-9 col-xl-10 col-lg-11 col-12 m-auto">
              <div className=" text-center">
                <div className="profileNameImage p-50-x d-flex align-items-center flex-sm-row flex-column justify-content-sm-start justify-content-center">
                  <div className="profileMainImage m-sm-30-right">
                    <div className="adminProfileImage hw-lg-120 hw-md-100 hw-80 m-auto  position-relative">
                      <div className="entryImage hw-25 bg-second-light border-second-blue-1 overflow-hidden ">
                        <i class="ri-pencil-fill fs-14 text-second"></i>
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          className="adminImage"
                          onChange={(e) => handleUploadImage(e)}
                        />
                      </div>
                      <div className="adminImageHeader hw-lg-120 hw-md-100 hw-80 m-auto overflow-hidden border-solid-second-3">
                        <img
                          src={
                            imagePath
                              ? imagePath
                              : baseURL + sessionUserId.image
                          }
                          alt="admin profile"
                          height={`100%`}
                          width={`100%`}
                        />
                      </div>
                    </div>
                    <div className="primeButton m-10-top fs-lg-12 fs-sm-10 fs-10">
                      <ActionButton
                        className={`bg-second-light text-second border-solid-second-2 fs-lg-12 fs-sm-10 fs-10`}
                        bIcon={`ri-file-pdf-line`}
                        text={`Update Image`}
                        onClick={handleImage}
                      />
                    </div>
                  </div>
                  <div className="adminText m-20-top text-sm-start text-center">
                    <h3 className="m-0 fs-lg-24 fs-sm-20 fs-xsm-16 fs-14 text-second">
                      {sessionUserId.name}
                    </h3>
                    <p className="fs-lg-16 fs-xsm-12 fs-12">
                      {sessionUserId.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row m-sm-40-top m-20-top">
                <div className="col-sm-6 col-12">
                  <form onSubmit={handleSubmit} id="adminDetailsForm">
                    <div className="adminDetails bg-light p-20">
                      <div className="adminProfileTitle betBox">
                        <div>Admin Information</div>

                        {editUserData ? (
                          <ActionButton
                            type={`submit`}
                            className={`bg-success-light text-success border-solid-success-2`}
                            bIcon={`ri-edit-2-line`}
                            text={`Update`}
                          />
                        ) : (
                          <Button
                            type={`button`}
                            className={`bg-blue-light text-blue border-solid-blue-2 fs-lg-12 fs-sm-10 fs-10`}
                            icon={`ri-edit-2-line`}
                            onClick={() => setEditUserData(true)}
                          />
                        )}
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Name</div>
                        <Input
                          type={`text`}
                          id={`name`}
                          name={`name`}
                          defaultValue={sessionUserId.name}
                          disabled={editUserData ? false : true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Email</div>
                        <Input
                          type={`text`}
                          id={`email`}
                          name={`email`}
                          defaultValue={sessionUserId.email}
                          disabled={editUserData ? false : true}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-sm-6 col-12">
                  <form onSubmit={handleSubmitPassword} id="adminPassForm">
                    <div className="adminDetails bg-light p-20 text-start">
                      <div className="adminProfileTitle betBox">
                        <div>Password Change</div>
                        {editUserPassData ? (
                          <ActionButton
                            type={`submit`}
                            className={`bg-success-light text-success border-solid-success-2`}
                            bIcon={`ri-edit-2-line`}
                            text={`Update`}
                          />
                        ) : (
                          <Button
                            type={`button`}
                            className={`bg-blue-light text-blue border-solid-blue-2 fs-lg-12 fs-sm-10 fs-10`}
                            icon={`ri-edit-2-line`}
                            onClick={() => setEditUserPassData(true)}
                          />
                        )}
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Old Password</div>
                        <Input
                          type={`password`}
                          value={sessionUserId.position}
                          className={`text-start`}
                          placeholder={`Old Password`}
                          id={`oldPassword`}
                          name={`oldPassword`}
                          disabled={editUserPassData ? false : true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">New Password</div>
                        <Input
                          type={`password`}
                          value={sessionUserId.staffCode}
                          className={`text-start`}
                          placeholder={`New Password`}
                          id={`password`}
                          name={`password`}
                          disabled={editUserPassData ? false : true}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
