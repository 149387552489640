import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import {
  leaveGet,
  staffLeaveDelete,
  staffLeaveGet,
  staffLeaveStatusUpdate,
} from "../../../../redux/slice/staffLeaveSlice";
import { warning } from "../../../extra/Swal/Alert";
import MaleImg from "../../../../assets/images/male.png";
import FemaleImg from "../../../../assets/images/female.png";
import { baseURL } from "../../../util/config";
import StaffLeaveAdd from "./StaffLeaveAdd";
import StaffLeaveDetails from "./StaffLeaveDetails";

const RejectLeave = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const { leavesAll, leaveTotal } = useSelector((state) => state.staffLeave);

  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  const payload = {
    leaveStatus: 3,
    page,
    limit: rowsPerPage,
    search,
  };

  // Server Get
  useEffect(() => {
    dispatch(leaveGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(leaveGet({ ...payload, command: true }));
  }, []);

  useEffect(() => {
    setData(leavesAll);
  }, [leavesAll]);

  const handleDeleteLeave = async (id) => {
    const data = warning("", "Remove Punch");
    let response;
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          response = await dispatch(staffLeaveDelete(id));
          console.log("response", response);
          if (response?.payload?.status) {
            dispatch(
              staffLeaveGet({
                staffId: response?.payload?.staffLeave?.staffId,
                year: response?.payload?.staffLeave?.year,
                command: false,
              })
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleLeaveStatus = async (id, status) => {
    const data = warning(
      "",
      "Penidng Leave",
      "Pending",
      "ri-arrow-go-back-line",
      "blue"
    );
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          dispatch(
            staffLeaveStatusUpdate({ leaveId: id, leaveStatus: status })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const staffLeaveTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>,
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="d-flex align-items-center">
          <span>
            <div className="height-30 border-round-50 width-30 bg-second m-20-right overflow-hidden">
              <img
                src={
                  row?.image
                    ? baseURL + row?.image
                    : row?.gender == "male"
                    ? MaleImg
                    : FemaleImg
                }
                alt=""
                height={`100%`}
              />
            </div>
          </span>
          <span className="m-15-right">{row?.staffName}</span>
        </span>
      ),
      sorting: { type: "client" },
      class: "text-center",
    },
    {
      Header: "Staff Code",
      body: "staffCode",
      bText: "#",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    // {
    //   Header: "Position",
    //   body: "position",
    //   sorting: { type: "client" },
    //   tdClass: "text-capitalize text-center ",
    //   thClass: "justify-content-center",
    // },
    {
      Header: "Month / Year",
      bBody: "month",
      bText: "/",
      bTextClass: "text-second",
      body: "year",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Status",
      body: "",
      Cell: ({ row }) =>
        row.isLeave && !row.isHalfLeave ? (
          <button
            className={`bg-danger-light text-danger border-solid-danger-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
          >
            Full Leave
          </button>
        ) : (
          !row.isLeave &&
          row.isHalfLeave && (
            <button
              className={`bg-darkGray-light text-darkGray border-darkGray-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
            >
              Half Leave
            </button>
          )
        ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Leave Date",
      bBody: "leaveFrom",
      bText: "to",
      bTextClass: "text-second",
      body: "leaveTo",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Day (Holiday",
      body: "allDays",
      aBody: "holidays",
      aBodyClass: "text-second",
      aText: "(",
      aTextClass: "text-second",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Days",
      body: "totalDays",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Leave Time",
      bBody: "timeFrom",
      bText: "to",
      bTextClass: "text-second",
      body: "timeTo",
      aBody: "breakTime",
      aBodyClass: "text-second",
      aText: "/",
      aTextClass: "text-second",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Hours",
      body: "totalHours",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Undo",
      body: "-",
      Cell: ({ row }) =>
        row.status == 2 || row.status == 3 ? (
          <ActionButton
            className="bg-blue-light text-blue  border-solid-blue-1  m-5-right "
            icon={`ri-arrow-go-back-line`}
            onClick={() => {
              handleLeaveStatus(row._id, 1);
            }}
          />
        ) : (
          row.status == 1 && (
            <button
              className={`bg-blue-light text-blue border-solid-blue-1 p-20-x p-4-y fs-12 text-capitalize p-6-x p-3-y fs-10 fw-600 border-radius-24`}
            >
              Pending
            </button>
          )
        ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      body: "-",
      Cell: ({ row }) => (
        <>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-solid-darkGray-1 m-5-right "
            icon={`ri-pencil-line`}
            onClick={() => {
              dispatch(openDialog({ type: "rejectLeave", data: { ...row } }));
            }}
          />
          <ActionButton
            className="bg-danger-light text-danger  border-solid-danger-1  m-5-right actionClick"
            icon={`ri-delete-bin-6-line`}
            onClick={() => {
              handleDeleteLeave(row._id);
            }}
          />
          <ActionButton
            className="bg-blue-light text-blue  border-solid-blue-1  m-5-right "
            icon={`ri-information-line`}
            onClick={() => {
              dispatch(
                openDialog({ type: "staffLeaveDetails", data: { ...row } })
              );
            }}
          />
        </>
      ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Reject Leave"} icon={`ri-user-shared-2-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div>
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={staffLeaveTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={staffLeaveTable}
        serverPerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={leaveTotal}
      />

      {dialogue && dialogueType === "rejectLeave" && <StaffLeaveAdd />}
      {dialogue && dialogueType === "staffLeaveDetails" && (
        <StaffLeaveDetails />
      )}
    </>
  );
};

export default RejectLeave;
