import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { editData, submitData } from "../../../extra/Inputs/Input";
import { ActionButton } from "../../../extra/Buttons/Button";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  staffHolidayAdd,
  staffHolidayUpdate,
} from "../../../../redux/slice/staffHolidaySlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";

const StaffHolidayAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [isManual, setIsManual] = useState(false);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "staffHolidaysForm");
      setIsManual(dialogueData?.isManual);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addHoliday = submitData(e);
    if (addHoliday) {
      let payload = { ...addHoliday, isManual };
      try {
        let response;
        if (dialogueData) {
          const editHoliday = {
            ...payload,
            staffHolidayId: dialogueData._id,
          };
          response = await dispatch(staffHolidayUpdate(editHoliday)).unwrap();
        } else {
          response = await dispatch(staffHolidayAdd(payload)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response?.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`staffHolidaysForm`}
      title={`Staff Holiday Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-12">
          <Input
            type={`date`}
            id={`holidayDate`}
            name={`holidayDate`}
            label={`Holiday Date`}
            errorMessage={`Enter Holiday Date`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`description`}
            name={`description`}
            label={`Description`}
            errorMessage={`Enter Description`}
          />
        </div>

        <div className="col-lg-6 col-12">
          <div className="inputData">
            <label>Manual Holiday ?</label>
          </div>
          <ActionButton
            type={`button`}
            className={`leaveType fs-14 ${
              isManual
                ? "bg-success text-light"
                : "bg-success-light text-success"
            }  border-solid-success-2 m-10-right m5-top`}
            text={`Yes`}
            onClick={() => setIsManual(true)}
          />
          <ActionButton
            type={`button`}
            className={`leaveType fs-14 ${
              !isManual ? "bg-danger text-light" : "bg-danger-light text-danger"
            }  border-solid-danger-2 m-10-right m5-top`}
            text={`No`}
            onClick={() => setIsManual(false)}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default StaffHolidayAdd;
