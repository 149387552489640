import React, { useEffect, useRef, useState } from "react";
import { isSkeleton } from "../Loader/allSelector";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./table.scss"

function Table(props) {
  const roleSkeleton = useSelector(isSkeleton);
  const { data, mapData, Page, PerPage, onChildValue, className, id } = props;
  console.log("data===", data);
  const location = useLocation();
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleColumnClick = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedData =
    data?.length > 0 &&
    [...data]?.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  const handleClick = (value) => {
    // Replace with your actual value
    onChildValue(value); // Invoke the callback function in the parent component
  };

  // const handleScroll = (event) => {
  //   const { scrollTop } = event.target;
  //   localStorage.setItem(id, scrollTop);
  // };

  // useEffect(() => {
  //   const myDiv = document.getElementById(id);
  //   myDiv?.addEventListener('scroll', handleScroll);

  //   return () => {
  //     myDiv?.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const yourScroll = localStorage.getItem(id);

  // useEffect(() => {
  //   const myDivv = document.getElementById(id);
  //   myDivv.scrollTop = parseInt(yourScroll);
  // }, []);

  console.log("sortedData======", sortedData);
  console.log("sortedData======length", sortedData.length);
  console.log("sortedData======PerPage", PerPage);
  return (
    <div className="mainTable" id={id}>
      <table
        width="100%"
        border
        className={`primeTable text-center ${className}`}
      >
        {roleSkeleton ? (
          <>
            <thead>
              <tr>
                {mapData.map((res, i) => {
                  return (
                    <th
                      className={`fw-bold ${res.thClass}`}
                      key={`thade`}
                      width={res.width}
                      style={{ minWidth: res.width ? res.width : "100px" }}
                    >
                      <div className={`${res.thClass}`}>
                        <div
                          className="skeleton"
                          style={{ height: "10px", width: "70%" }}
                        >{`${" "}`}</div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Array(6)
                .fill()
                .map(() => {
                  return (
                    <>
                      <tr key={`table`} style={{ height: "80px" }}>
                        {mapData?.map((res) => {
                          return (
                            <td key={`tbody`}>
                              <div
                                className="skeleton"
                                style={{ height: "20px", width: "70%" }}
                              ></div>
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </>
        ) : (
          <>
            <thead>
              <tr>
                {mapData.map((res, i) => {
                  return (
                    <th
                      className={`fw-bold ${res.thClass}`}
                      key={`thade`}
                      width={res.width}
                    >
                      <div
                        className={`d-flex align-items-center ${res.thClass}`}
                      >
                        <div className="fw-600">{`${" "}${res.Header}`}</div>
                        {res?.sorting?.type === "server" && (
                          <i
                            className="ri-arrow-up-down-line fw-100 sortIcon m-8-left"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(res.body)}
                          ></i>
                        )}
                        {res?.sorting?.type === "client" && (
                          <i
                            className="ri-arrow-up-down-line fw-100 sortIcon m-8-left"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleColumnClick(res.body)}
                          ></i>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {sortedData.length > 0 ? (
                <>
                  {(PerPage > 0
                    ? sortedData.slice(Page * PerPage, Page * PerPage + PerPage)
                    : sortedData
                  ).map((i, k) => {
                    return (
                      <>
                        <tr key={`table`}>
                          {mapData?.map((res) => {
                            return (
                              <td key={`tbody`} className={res.tdClass}>
                                {res.Cell ? (
                                  <res.Cell row={i} index={k} />
                                ) : (
                                  <span>
                                    {i[res?.bBody] && (
                                      <span
                                        className={`m-2-x ${res?.bBodyClass}`}
                                      >
                                        {i[res?.bBody]}
                                      </span>
                                    )}
                                    {res?.bText && (
                                      <span
                                        className={`m-2-x ${res?.bTextClass}`}
                                      >
                                        {res?.bText}
                                      </span>
                                    )}
                                    <span className={`m-2-x ${res?.mainClass}`}>
                                      {i[res?.body]}
                                    </span>

                                    {res?.aText && (
                                      <span
                                        className={`m-2-x ${res?.aTextClass}`}
                                      >
                                        {res?.aText}
                                      </span>
                                    )}
                                    {i[res?.aBody] && (
                                      <span
                                        className={`m-2-x ${res?.aBodyClass}`}
                                      >
                                        {i[res?.aBody]}
                                      </span>
                                    )}
                                  </span>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="25" className="text-center">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
}

export default Table;
