import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { studentLeaveGet } from "../../../../redux/slice/studentLeaveSlice";
import { warning } from "../../../extra/Swal/Alert";
const StudentLeave = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { studentLeave, leaveTotal } = useSelector(
    (state) => state.studentLeave
  );

  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
    status: 1,
  };
  // Server Get
  useEffect(() => {
    dispatch(studentLeaveGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(studentLeaveGet({ ...payload, command: true }));
  }, []);
  useEffect(() => {
    setData(studentLeave);
  }, [studentLeave]);

  const [option, setOption] = useState([]);
  const { student } = useSelector((state) => state.student);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          // dispatch(leaveDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };
  // const handleStatus = (id, status) => {
  //   dispatch(leaveStatusUpdate({ id, status }));
  // };
  const leaveTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="d-flex align-items-center">
          <span className="m-15-right">{row?.studentName}</span>
        </span>
      ),
      sorting: { type: "client" },
      class: "text-center",
    },
    { Header: "Form No", body: "formNo" },
    { Header: "Leave Day", body: "leaveDay" },
    {
      Header: "Leave Start Date",
      body: "startDate",
      sorting: { type: "client" },
    },
    { Header: "Leave End Date", body: "endDate", sorting: { type: "client" } },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <button
            className="bg-second text-light m-5-right p-10-x p-4-y fs-12"
            onClick={() => dispatch(openDialog({ type: "leave", data: row }))}
          >
            <i class="ri-pencil-fill"></i>
          </button>
          <button
            className="bg-blue m-5-right text-light p-10-x p-4-y fs-12"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "leaveDetails",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
          >
            <i class="ri-information-line"></i>
          </button>
          <button
            className="bg-prime m-5-right text-light p-10-x p-4-y fs-12"
            onClick={() => handleDelete(row._id)}
          >
            <i class="ri-delete-bin-line"></i>
          </button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="row justify-content-between">
        <div className="col-3">
          <Title name={"Student Leave"} />
        </div>
        <div className="col-3">
          <Searching
            type={`server`}
            data={data}
            setData={setData}
            column={leaveTable}
            serverSearching={handleFilterData}
            className={`w-100`}
          />
        </div>
        <div className="col-3 text-end">
          <Button
            className={`bg-second text-light m-20-right`}
            text={`ADD`}
            bIcon={`ri-add-line`}
            onClick={() => {
              dispatch(openDialog({ type: "leave" }));
            }}
          />
        </div>
      </div>

      <Table
        data={data}
        mapData={leaveTable}
        serverPerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={leaveTotal}
      />

      {/* {dialogue && dialogueType === "leave" && <LeaveAdd />}
      {dialogue && dialogueType === "leaveDetails" && <LeaveDetails />} */}
    </div>
  );
};

export default StudentLeave;
