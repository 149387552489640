import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
import {
  staffHolidayDelete,
  staffHolidayGet,
} from "../../../../redux/slice/staffHolidaySlice";
import StaffHolidayAdd from "./StaffHolidayAdd";
import { Select } from "../../../extra/Inputs/Input";
const StaffHoliday = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [years, setYears] = useState("2024");
  const staffLeaveYear = ["2024", "2025"];

  const { staffHoliday } = useSelector((state) => state.staffHoliday);
  // Pagination BOTH

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(staffHolidayGet(years));
  }, [years]);

  useEffect(() => {
    dispatch(staffHolidayGet(years));
  }, []);

  useEffect(() => {
    setData(staffHoliday);
  }, [staffHoliday]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(staffHolidayDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const staffHolidayTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Holiday Date",
      body: "holidayDate",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Day",
      body: "holidayDay",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Month",
      body: "month",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Manual",
      body: "isManual",
      Cell: ({ row }) => (
        <span className="fs-20">
          {row.isManual ? (
            <i class="ri-checkbox-circle-fill text-success"></i>
          ) : (
            <i class="ri-close-circle-fill text-second"></i>
          )}
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Description",
      body: "description",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(openDialog({ type: "staffHoliday", data: row }))
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-second-light text-second border-solid-second-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Staff Holiday"} icon={`ri-calendar-check-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`client`}
              data={staffHoliday}
              setData={setData}
              column={staffHolidayTable}
              className={`w-100`}
            />
          </div>
          <div>
            <Select
              option={staffLeaveYear}
              defaultValue={years}
              onChange={(e) => setYears(e)}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m20-left`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "staffHoliday" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={staffHolidayTable}
        PerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data.length}
      />
      {dialogue && dialogueType === "staffHoliday" && <StaffHolidayAdd />}
    </>
  );
};

export default StaffHoliday;
