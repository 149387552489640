import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import { ActionButton } from "../../../extra/Buttons/Button";
import StaffHolidayAdd from "../StaffHoliday/StaffHolidayAdd";
import { staffSalaryReceipts } from "../../../../redux/slice/staffSalarySlice";
import { PrintSalaryReceipt } from "./StaffSalary";
const SalaryReceipts = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { salaryReceipts, salaryReceiptsCount } = useSelector(
    (state) => state.staffSalary
  );
  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  const payload = {
    page,
    limit: rowsPerPage,
    search,
  };
  // Server Get
  useEffect(() => {
    dispatch(staffSalaryReceipts({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(staffSalaryReceipts({ ...payload, command: true }));
  }, []);

  useEffect(() => {
    setData(salaryReceipts);
  }, [salaryReceipts]);

  const [prints, setPrints] = useState(0);
  const [datas, setDatas] = useState({});

  const staffReceiptsTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Salary ReceiptNo",
      body: "salaryReceiptNo",
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Staff Name",
      body: "staffName",
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Position",
      body: "position",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Salary",
      body: "totalPayingStaffSalary",
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-success-light text-success border-solid-success-1  m-5-right"
            onClick={() => {
              setPrints(1);
              setDatas(row?.salaryreceipt);
            }}
            icon={`ri-printer-fill`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Staff Receipt"} icon={`ri-file-paper-2-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div>
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={staffReceiptsTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={staffReceiptsTable}
        serverPerPage={rowsPerPage}
        Page={page}
      />

      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={salaryReceiptsCount}
      />

      {dialogue && dialogueType === "staffHoliday" && <StaffHolidayAdd />}
      {prints == 1 && (
        <PrintSalaryReceipt setPrints={setPrints} datas={datas} />
      )}
    </>
  );
};

export default SalaryReceipts;
