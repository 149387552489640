import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashBoardGet,
  employeeDashData,
  getLoginUser,
} from "../../../redux/slice/dashBoardSlice";
import Title from "../../extra/Title/Title";

const Dashboard = () => {
  const { dashBoard, loginUser } = useSelector((state) => state.dashBoard);

  const { admin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getLoginUser(admin._id));
  }, [admin._id]);

  useEffect(() => {
    dispatch(dashBoardGet({ command: true }));
  }, []);

  useEffect(() => {
    if (admin?._id) {
      dispatch(
        employeeDashData({
          staffId: admin._id,
          monthId: 3,
          year: 2024,
          command: true,
        })
      );
    }
  }, [admin._id]);

  useEffect(() => {
    setData(dashBoard);
  }, [dashBoard]);

  return (
    <div className="mainDashboard">
      <div className="dashBoardHead betBox">
        <div className="dashHeadText">
          <Title name={"Dashboard"} icon={`ri-home-5-line`} />
        </div>
      </div>

      <div className="dashBoardBody">
        <div className="hrSystem m-20-bottom p-20-bottom border-bottom-solid-gray2-1">
          <Title name={`Employee Data`} className={`m-10-bottom`} />
          <div className="row">
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Staff`}
                count={dashBoard?.totalStaff}
                className={`p-10`}
                classNameBody={`fs-22 text-success`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`success`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Staff Leave`}
                count={dashBoard?.totalStaffLeave}
                className={`p-10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Staff Holiday`}
                count={dashBoard?.totalStaffHoliday}
                className={`p-10`}
                classNameBody={`fs-22 text-success`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`success`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Staff Receipt`}
                count={dashBoard?.totalStaffSalaryReceipt}
                className={`p-10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
          </div>
        </div>

        <div className="reptionistSystem m-20-bottom p-20-bottom">
          <Title name={`Student Data`} className={`m-10-bottom`} />
          <div className="row">
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Student`}
                count={dashBoard?.totalStudent}
                className={`p-10`}
                classNameBody={`fs-22 text-orange`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`orange`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Student Leave`}
                count={dashBoard?.totalStudentLeave}
                className={`p-10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Student Holiday`}
                count={dashBoard?.totalStudentHoliday}
                className={`p-10`}
                classNameBody={`fs-22 text-orange`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`orange`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Student Course`}
                count={dashBoard?.totalStudentCourse}
                className={`p-10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Student Batch`}
                count={dashBoard?.totalStudentBatch}
                className={`p-10`}
                classNameBody={`fs-22 text-orange`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`orange`}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m-20-bottom">
              <DashBox
                title={`Total Student Receipt`}
                count={dashBoard?.totalStudentReceipt}
                className={`p-10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

export const DashBox = ({
  title,
  count,
  color,
  icon,
  sideText,
  className,
  classNameHead,
  classNameBody,
}) => {
  return (
    <div
      className={`dashBox betBox align-items-end ${className} border-bottom-solid-${color}-3 bg-light`}
    >
      <div>
        <div className={`boxHead betBox`}>
          <p className={`${classNameHead}`}>{title}</p>
        </div>
        <div className={`boxBody m-5-sm-top m-2-top d-flex align-items-end`}>
          <h2 className={`m-0 ${classNameBody}`} style={{ color: `${color}` }}>
            {count}
          </h2>
          <p className="fs-12 m-5-left text-darkGary fw-500">{sideText}</p>
        </div>
      </div>

      {icon && (
        <div
          className={`boxIcon m-md-20-right m-12-right d-flex justify-content-center align-items-center fs-sm-22 fs-20 hw-md-35 hw-25 bg-${color} text-light`}
          style={{
            color: `${color}`,
            backgroundColor: `${color}40`,
            borderRadius: "7px",
          }}
        >
          <i className={icon}></i>
        </div>
      )}
    </div>
  );
};
